.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App {
    background-color: #000000;
    min-height: 98vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* font-size: calc(10px + 2vmin); */
    color: #d4af37;
    text-align: center;
    height: 100vh;
    width: 100vw;
    margin: 1vh auto 0 auto;
    overflow: auto;
    position: relative;
}

.App-link {
    color: #d4af37;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.dAppLogo {
    height: 250px;  
}

@media screen and (max-width: 320px) {
.dAppTitle {
    margin: 0;
    font-size: 48px;
    color: #d4af37
}

}

.dAppDescription {
    font-size: 18px;
    margin: 0 0 15px 0;
    color: #d4af37
}

.address {
    font-size: 18px;
    margin: 0;
    color: #d4af37
}

.btn {
    border: 1px solid #d4af37;
    background: rgb(0, 0, 0);
    color: #d4af37;
    font-size: 18px;
    padding: 5px 10px;
    border-radius: 3px;
    width: 100%;
    
}
.btn:disabled,
.btn:disabled:hover {
    background: #d4af37;
    color: rgb(0, 0, 0);
}
.btn:hover {
    background-color: #434343;
    color: #d4af37;
}

.btn-50 {
    width: 50%;
}

.options {
    display: flex;
    gap: 15px;
    margin-top: 15px;
    width: 420px;
    justify-content: space-between;
    margin: 15px auto;
}

.balance {
    font-size: 16px;
    display: flex;
    align-items: center;
    border: 1px solid #d4af37;
    padding: 5px 15px;
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
}

.balance strong {
    margin: 0 5px;
}
